var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("정산 내역")]),_c('v-card-text',[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v("상태")]),_c('v-select',{staticClass:"mr-4",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.loanStatusOptions,"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filters.loanStatus),callback:function ($$v) {_vm.$set(_vm.filters, "loanStatus", $$v)},expression:"filters.loanStatus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"max-width":"150px"},attrs:{"readonly":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu.start),callback:function ($$v) {_vm.$set(_vm.dateMenu, "start", $$v)},expression:"dateMenu.start"}},[_c('v-date-picker',{on:{"change":function($event){_vm.dateMenu.start = false}},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}})],1),_c('span',{staticClass:"mx-2"},[_vm._v("~")]),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-4",staticStyle:{"max-width":"150px"},attrs:{"readonly":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu.end),callback:function ($$v) {_vm.$set(_vm.dateMenu, "end", $$v)},expression:"dateMenu.end"}},[_c('v-date-picker',{on:{"change":function($event){_vm.dateMenu.end = false}},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}})],1),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.setDateRange('today')}}},[_vm._v("당일")]),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.setDateRange('week')}}},[_vm._v("일주일")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.setDateRange('month')}}},[_vm._v("1개월")])],1)])],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.search}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" 검색 ")],1),_c('v-btn',{on:{"click":_vm.resetFilters}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" 초기화 ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 settlement-table",attrs:{"headers":_vm.headers,"items":_vm.settlements,"loading":_vm.loading,"server-items-length":_vm.totalSettlements,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
        'items-per-page-options': [15],
        showFirstLastPage: true
      },"dense":"","hover":false,"disable-sort":false,"disable-pagination":false,"disable-filtering":false},on:{"update:page":function($event){_vm.page = $event},"update:items-per-page":function($event){_vm.itemsPerPage = $event},"update:sort-by":function($event){_vm.sortBy = $event},"update:sort-desc":function($event){_vm.sortDesc = $event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"clickable-cell",on:{"click":function($event){$event.stopPropagation();return _vm.handleRowClick(item)}}},[_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")])]}},{key:"item.user.store.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.store.name)+" ")]}},{key:"item.loan.status",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.loan ? _vm.getLoanStatusLabel(item.loan.status) : '-')+" ")]}},{key:"item.salesTotal.salesAmount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.salesTotal.salesAmount))+" ")]}},{key:"item.salesTotal.expectedFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.salesTotal.expectedFee))+" ")]}},{key:"item.serviceFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.serviceFee))+" ")]}},{key:"item.adjust",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.adjust))+" ")]}},{key:"item.refund",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:{ 'red--text': item.refund > 0 }},[_vm._v(" "+_vm._s(_vm.formatAmount(item.refund))+" ")])]}},{key:"item.nowPayment",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.nowPayment))+" ")]}},{key:"item.loan.amount",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"clickable-cell",on:{"click":function($event){$event.stopPropagation();return _vm.showLoanId(item)}}},[_vm._v(" "+_vm._s(item.loan ? _vm.formatAmount(item.loan.amount) : '-')+" ")])]}},{key:"item.loan.balance",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.loan ? _vm.formatAmount(item.loan.balance) : '-')+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_vm._v("합계")]),_c('td'),_c('td'),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatAmount(_vm.totalRow.salesTotal.salesAmount)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatAmount(_vm.totalRow.salesTotal.expectedFee)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatAmount(_vm.totalRow.serviceFee)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatAmount(_vm.totalRow.adjust)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatAmount(_vm.totalRow.refund)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatAmount(_vm.totalRow.nowPayment)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatAmount(_vm.totalRow.loan.amount)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatAmount(_vm.totalRow.loan.balance)))])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }