<template>
  <v-card outlined>
    <v-card-title>정산 내역</v-card-title>
    <v-card-text>
      <!-- 필터 영역 -->
      <v-row class="mb-4">
        <v-col cols="12">
          <div class="d-flex align-center">
            <div class="mr-2">상태</div>
            <v-select
              v-model="filters.loanStatus"
              :items="loanStatusOptions"
              dense
              outlined
              hide-details
              class="mr-4"
              style="max-width: 200px"
            ></v-select>

            <v-menu
              v-model="dateMenu.start"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.startDate"
                  readonly
                  outlined
                  dense
                  hide-details
                  class="mr-2"
                  style="max-width: 150px"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.startDate"
                @change="dateMenu.start = false"
              ></v-date-picker>
            </v-menu>

            <span class="mx-2">~</span>

            <v-menu
              v-model="dateMenu.end"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.endDate"
                  readonly
                  outlined
                  dense
                  hide-details
                  class="mr-4"
                  style="max-width: 150px"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.endDate"
                @change="dateMenu.end = false"
              ></v-date-picker>
            </v-menu>

            <v-btn
              small
              color="primary"
              class="mr-2"
              @click="setDateRange('today')"
              >당일</v-btn
            >
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click="setDateRange('week')"
              >일주일</v-btn
            >
            <v-btn small color="primary" @click="setDateRange('month')"
              >1개월</v-btn
            >
          </div>
        </v-col>
      </v-row>

      <!-- 검색/초기화 버튼 -->
      <v-row class="mb-4">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn color="primary" class="mr-2" @click="search">
            <v-icon left>mdi-magnify</v-icon>
            검색
          </v-btn>
          <v-btn @click="resetFilters">
            <v-icon left>mdi-refresh</v-icon>
            초기화
          </v-btn>
        </v-col>
      </v-row>

      <!-- 정산 내역 테이블 -->
      <v-data-table
        :headers="headers"
        :items="settlements"
        :loading="loading"
        :server-items-length="totalSettlements"
        :page="page"
        :items-per-page="itemsPerPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :footer-props="{
          'items-per-page-options': [15],
          showFirstLastPage: true
        }"
        dense
        :hover="false"
        class="elevation-1 settlement-table"
        :disable-sort="false"
        :disable-pagination="false"
        :disable-filtering="false"
        @update:page="page = $event"
        @update:items-per-page="itemsPerPage = $event"
        @update:sort-by="sortBy = $event"
        @update:sort-desc="sortDesc = $event"
      >
        <!-- 정산일 -->
        <template v-slot:item.date="{ item }">
          <span @click.stop="handleRowClick(item)" class="clickable-cell">
            {{ formatDate(item.date) }}
          </span>
        </template>

        <!-- 가맹점 -->
        <template v-slot:item.user.store.name="{ item }">
          {{ item.user.store.name }}
        </template>

        <!-- 상태 -->
        <template v-slot:item.loan.status="{ item }">
          {{ item.loan ? getLoanStatusLabel(item.loan.status) : '-' }}
        </template>

        <!-- 정산대상 매출합 -->
        <template v-slot:item.salesTotal.salesAmount="{ item }">
          {{ formatAmount(item.salesTotal.salesAmount) }}
        </template>

        <!-- 카드매입 수수료 -->
        <template v-slot:item.salesTotal.expectedFee="{ item }">
          {{ formatAmount(item.salesTotal.expectedFee) }}
        </template>

        <!-- 운영수수료 -->
        <template v-slot:item.serviceFee="{ item }">
          {{ formatAmount(item.serviceFee) }}
        </template>

        <!-- 미정산금 -->
        <template v-slot:item.adjust="{ item }">
          {{ formatAmount(item.adjust) }}
        </template>

        <!-- 과납금 -->
        <template v-slot:item.refund="{ item }">
          <span :class="{ 'red--text': item.refund > 0 }">
            {{ formatAmount(item.refund) }}
          </span>
        </template>

        <!-- 고객계좌 입금액 -->
        <template v-slot:item.nowPayment="{ item }">
          {{ formatAmount(item.nowPayment) }}
        </template>

        <!-- 대출 원금 -->
        <template v-slot:item.loan.amount="{ item }">
          <span @click.stop="showLoanId(item)" class="clickable-cell">
            {{ item.loan ? formatAmount(item.loan.amount) : '-' }}
          </span>
        </template>

        <!-- 대출 잔액 -->
        <template v-slot:item.loan.balance="{ item }">
          {{ item.loan ? formatAmount(item.loan.balance) : '-' }}
        </template>

        <!-- 합계 행 -->
        <template v-slot:body.append>
          <tr>
            <td>합계</td>
            <td></td>
            <td></td>
            <td class="text-right">{{ formatAmount(totalRow.salesTotal.salesAmount) }}</td>
            <td class="text-right">{{ formatAmount(totalRow.salesTotal.expectedFee) }}</td>
            <td class="text-right">{{ formatAmount(totalRow.serviceFee) }}</td>
            <td class="text-right">{{ formatAmount(totalRow.adjust) }}</td>
            <td class="text-right">{{ formatAmount(totalRow.refund) }}</td>
            <td class="text-right">{{ formatAmount(totalRow.nowPayment) }}</td>
            <td class="text-right">{{ formatAmount(totalRow.loan.amount) }}</td>
            <td class="text-right">{{ formatAmount(totalRow.loan.balance) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SettlementInfo',
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    const endDate = new Date()
    const startDate = new Date()
    startDate.setDate(endDate.getDate() - 7)

    const codes = this.$store.getters['myfinbiz/auth/getCodes']
    const statusCodes = codes?.fundanow?.LoansLoanStatus || []
    const loanStatusList = statusCodes.map((status) => ({
      text: status.label,
      value: status.code,
    }))

    return {
      loading: false,
      settlements: [],
      totalSettlements: 0,
      dateMenu: {
        start: false,
        end: false,
      },
      filters: {
        loanStatus: '',
        startDate: startDate.toISOString().substr(0, 10),
        endDate: endDate.toISOString().substr(0, 10),
      },
      loanStatusOptions: [{ text: '전체', value: '' }, ...loanStatusList],
      page: 1,
      itemsPerPage: 15,
      sortBy: ['date'],
      sortDesc: [true],
      headers: [
        { text: '정산일', value: 'date', sortable: true },
        { text: '가맹점', value: 'user.store.name', sortable: true },
        { text: '상태', value: 'loan.status', sortable: true },
        {
          text: '정산대상 매출합',
          value: 'salesTotal.salesAmount',
          align: 'right',
          sortable: true,
        },
        {
          text: '카드배달 수수료',
          value: 'salesTotal.expectedFee',
          align: 'right',
          sortable: true,
        },
        {
          text: '운영수수료(집금대행)',
          value: 'serviceFee',
          align: 'right',
          sortable: true,
        },
        { text: '미정산금', value: 'adjust', align: 'right', sortable: true },
        { text: '과납금', value: 'refund', align: 'right', sortable: true },
        {
          text: '고객계좌 입금액',
          value: 'nowPayment',
          align: 'right',
          sortable: true,
        },
        {
          text: '대출 원금',
          value: 'loan.amount',
          align: 'right',
          sortable: true,
        },
        {
          text: '대출 잔액',
          value: 'loan.balance',
          align: 'right',
          sortable: true,
        },
      ],
      totalRow: {
        salesTotal: {
          salesAmount: 0,
          expectedFee: 0,
        },
        serviceFee: 0,
        adjust: 0,
        refund: 0,
        nowPayment: 0,
        loan: {
          amount: 0,
          balance: 0,
        },
      },
    }
  },

  watch: {
    page() {
      this.loadSettlements()
    },
    itemsPerPage() {
      this.loadSettlements()
    },
    sortBy() {
      this.loadSettlements()
    },
    sortDesc() {
      this.loadSettlements()
    },
  },

  created() {
    this.loadSettlements()
  },

  methods: {
    async loadSettlements() {
      if (!this.customerId) return

      this.loading = true
      try {
        const response = await this.$myfinbizApi.customer.getSettlements(
          this.customerId,
          {
            page: this.page,
            limit: this.itemsPerPage,
            loanStatus: this.filters.loanStatus,
            startDate: this.filters.startDate,
            endDate: this.filters.endDate,
          }
        )

        const responseData = response.data?.data.data
        if (!responseData) {
          console.warn('Unexpected response structure:', response)
          this.settlements = []
          this.totalSettlements = 0
          return
        }

        // 전체 데이터 개수 설정
        this.totalSettlements = responseData.total || 0

        console.log('!!!!!!!!!!!this.totalSettlements', this.totalSettlements)

        // 현재 페이지 데이터
        const currentPageData = Array.isArray(responseData.data, responseData)
          ? responseData.data
          : []

        // 데이터 가공
        const processedSettlements = currentPageData.map((item) => {
          const temp = { ...item }

          // serviceFee 계산
          temp.serviceFee = Array.isArray(item.connectTransactions)
            ? item.connectTransactions.reduce(
              (sum, transaction) =>
                sum +
                  (transaction.type === 'service_fee'
                    ? transaction.amount || 0
                    : 0),
              0
            )
            : 0

          // nowPayment 계산
          const others = Array.isArray(item.connectTransactions)
            ? item.connectTransactions.reduce(
              (sum, transaction) => sum + (transaction.amount || 0),
              0
            ) + (item.deductibleAmount || 0)
            : item.deductibleAmount || 0

          temp.nowPayment =
            (item.amount || 0) - (item.fee || 0) - others + (item.refund || 0)

          return temp
        })

        // 정렬 처리
        const sortedSettlements = processedSettlements.sort((a, b) => {
          const sortBy = this.sortBy[0]
          const sortDesc = this.sortDesc[0]
          if (!sortBy) return 0

          let aValue, bValue

          switch (sortBy) {
            case 'date':
              aValue = a.date
              bValue = b.date
              break
            case 'user.store.name':
              aValue = a.user?.store?.name
              bValue = b.user?.store?.name
              break
            case 'loan.status':
              aValue = a.loan?.status
              bValue = b.loan?.status
              break
            case 'salesTotal.salesAmount':
              aValue = a.salesTotal?.salesAmount
              bValue = b.salesTotal?.salesAmount
              break
            case 'salesTotal.expectedFee':
              aValue = a.salesTotal?.expectedFee
              bValue = b.salesTotal?.expectedFee
              break
            case 'serviceFee':
              aValue = a.serviceFee
              bValue = b.serviceFee
              break
            case 'adjust':
              aValue = a.adjust
              bValue = b.adjust
              break
            case 'refund':
              aValue = a.refund
              bValue = b.refund
              break
            case 'nowPayment':
              aValue = a.nowPayment
              bValue = b.nowPayment
              break
            case 'loan.amount':
              aValue = a.loan?.amount
              bValue = b.loan?.amount
              break
            case 'loan.balance':
              aValue = a.loan?.balance
              bValue = b.loan?.balance
              break
            default:
              return 0
          }

          // null이나 undefined 처리
          if (aValue === null || aValue === undefined) return 1
          if (bValue === null || bValue === undefined) return -1

          // 숫자 비교
          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortDesc ? bValue - aValue : aValue - bValue
          }

          // 문자열 비교
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortDesc
              ? bValue.localeCompare(aValue)
              : aValue.localeCompare(bValue)
          }

          return 0
        })

        // 데이터 설정
        this.settlements = sortedSettlements

        // 합계 계산 (현재 페이지 데이터만)
        this.totalRow = {
          salesTotal: {
            salesAmount: processedSettlements.reduce(
              (sum, item) => sum + (item.salesTotal?.salesAmount || 0),
              0
            ),
            expectedFee: processedSettlements.reduce(
              (sum, item) => sum + (item.salesTotal?.expectedFee || 0),
              0
            ),
          },
          serviceFee: processedSettlements.reduce(
            (sum, item) => sum + (item.serviceFee || 0),
            0
          ),
          adjust: processedSettlements.reduce(
            (sum, item) => sum + (item.adjust || 0),
            0
          ),
          refund: processedSettlements.reduce(
            (sum, item) => sum + (item.refund || 0),
            0
          ),
          nowPayment: processedSettlements.reduce(
            (sum, item) => sum + (item.nowPayment || 0),
            0
          ),
          loan: {
            amount: processedSettlements.reduce(
              (sum, item) => sum + (item.loan?.amount || 0),
              0
            ),
            balance: processedSettlements.reduce(
              (sum, item) => sum + (item.loan?.balance || 0),
              0
            ),
          },
        }
      } catch (error) {
        console.error('Failed to load settlements:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '정산 내역을 불러오는데 실패했습니다.',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },

    formatAmount(amount) {
      if (typeof amount !== 'number') return '0'
      return amount.toLocaleString()
    },

    search() {
      this.page = 1
      this.loadSettlements()
    },

    setDateRange(range) {
      const end = new Date()
      let start = new Date()

      switch (range) {
        case 'today':
          start.setDate(end.getDate())
          end.setDate(end.getDate())
          break
        case 'week':
          start.setDate(end.getDate() - 7)
          break
        case 'month':
          start.setMonth(end.getMonth() - 1)
          break
      }

      this.filters.startDate = start.toISOString().substr(0, 10)
      this.filters.endDate = end.toISOString().substr(0, 10)
      this.search()
    },

    resetFilters() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setDate(endDate.getDate() - 7)

      this.filters = {
        loanStatus: '',
        startDate: startDate.toISOString().substr(0, 10),
        endDate: endDate.toISOString().substr(0, 10),
      }
      this.search()
    },

    getLoanStatusLabel(status) {
      if (!status) return ''
      const codes = this.$store.getters['myfinbiz/auth/getCodes']
      if (!codes || !codes.fundanow || !codes.fundanow.LoansLoanStatus) {
        return status
      }
      const statusCode = codes.fundanow.LoansLoanStatus.find(
        (code) => code.code === status
      )
      return statusCode ? statusCode.label : status
    },

    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')}`
    },

    handleOptionsUpdate(options) {
      this.page = options.page
      this.itemsPerPage = options.itemsPerPage
      this.sortBy = options.sortBy
      this.sortDesc = options.sortDesc
      this.loadSettlements()
    },

    handleRowClick(item) {
      // 현재 상태를 로컬 스토리지에 저장
      const currentState = {
        filters: this.filters,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
      localStorage.setItem('settlementInfoState', JSON.stringify(currentState))

      this.$router.push({
        path: `/myfinbiz/customer/${this.customerId}/settlement/${item.id}`,
        query: {
          date: item.date,
          from: 'settlement'
        }
      })
    },

    showLoanId(item) {
      if (item.loan && item.loan.id) {
        window.open(`/fundanow/loans/${item.loan.id}/detail`, '_blank')
      } else {
        alert('대출 정보가 없습니다.')
      }
    }
  },
}
</script>

<style scoped>
.v-data-table ::v-deep td {
  height: 40px !important;
}

.settlement-table ::v-deep tbody tr {
  cursor: default;
}

.clickable-cell {
  cursor: pointer;
  text-decoration: underline;
  color: #1976d2;
}

.clickable-cell:hover {
  color: #2196f3;
  font-weight: 500;
}
</style>
