<template>
  <v-card outlined>
    <v-card-title>반환 내역</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="refunds"
        :loading="loading"
        :server-items-length="totalRefunds"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
        dense
      >
        <!-- 금액 -->
        <template v-slot:item.amount="{ item }">
          {{ formatAmount(item.amount) }}
        </template>

        <!-- 시간 -->
        <template v-slot:item.created="{ item }">
          {{ formatDate(item.created) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RefundInfo',

  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      refunds: [],
      totalRefunds: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['created'],
        sortDesc: [true]
      },
      headers: [
        { text: '시간', value: 'created', width: '200' },
        { text: '금액', value: 'amount' }
      ]
    }
  },

  computed: {
    customerId() {
      return this.customer?.id
    }
  },

  watch: {
    options: {
      handler() {
        this.loadRefunds()
      },
      deep: true
    }
  },

  created() {
    this.loadRefunds()
  },

  methods: {
    async loadRefunds() {
      if (!this.customerId) return

      this.loading = true
      try {
        const { page, itemsPerPage } = this.options
        const response = await this.$myfinbizApi.customer.getRefunds(this.customerId, {
          page,
          limit: itemsPerPage
        })
        const refundData = response.data?.data?.data
        if (refundData) {
          this.refunds = refundData.data
          this.totalRefunds = refundData.total
        }
      } catch (error) {
        console.error('Failed to load refunds:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '반환 내역을 불러오는데 실패했습니다.',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },

    formatAmount(amount) {
      return new Intl.NumberFormat('ko-KR').format(amount) + '원'
    },

    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return date.toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      })
    }
  }
}
</script>

<style scoped>
.v-data-table ::v-deep td {
  height: 40px !important;
}
</style>
