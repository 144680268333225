var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("신용조회 기록")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.creditEvaluations,"loading":_vm.loading,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.grade",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getGradeColor(item.grade)}},[_vm._v(" "+_vm._s(item.grade)+" ")])]}},{key:"item.isQualified",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.isQualified ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.isQualified ? '통과' : '미통과')+" ")])]}},{key:"item.hasOverdueLoans",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.hasOverdueLoans ? 'error' : 'success'}},[_vm._v(" "+_vm._s(item.hasOverdueLoans ? '연체' : '정상')+" ")])]}},{key:"item.statusChanged",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.statusChanged))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }