<template>
  <v-card outlined>
    <v-card-title>서류 및 동의</v-card-title>
    <v-card-text>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>신분증</v-list-item-title>
            <v-list-item-subtitle v-if="documentUrls.id">
              {{ getFileName(documentUrls.id) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex">
            <v-btn small color="primary" class="mr-2" @click="handleUpload('id')">등록</v-btn>
            <v-btn small color="secondary" @click="handleView('id')" :disabled="!documentUrls.id">조회</v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>사업자등록증</v-list-item-title>
            <v-list-item-subtitle v-if="documentUrls.business">
              {{ getFileName(documentUrls.business) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex">
            <v-btn small color="primary" class="mr-2" @click="handleUpload('business')">등록</v-btn>
            <v-btn small color="secondary" @click="handleView('business')" :disabled="!documentUrls.business">조회</v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>은행사본</v-list-item-title>
            <v-list-item-subtitle v-if="documentUrls.bank">
              {{ getFileName(documentUrls.bank) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex">
            <v-btn small color="primary" class="mr-2" @click="handleUpload('bank')">등록</v-btn>
            <v-btn small color="secondary" @click="handleView('bank')" :disabled="!documentUrls.bank">조회</v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>소득금액증명원</v-list-item-title>
            <v-list-item-subtitle v-if="documentUrls.income">
              {{ getFileName(documentUrls.income) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex">
            <v-btn small color="primary" class="mr-2" @click="handleUpload('income')">등록</v-btn>
            <v-btn small color="secondary" @click="handleView('income')" :disabled="!documentUrls.income">조회</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
const DOCUMENT_FILE_MAPPING = {
  id: 'identificationFile',
  business: 'businessLicenseFile',
  bank: 'bankbookFile',
  income: 'certificateOfIncomeAmountFile'
}

export default {
  name: 'DocumentInfo',
  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      documentUrls: {
        id: null,
        business: null,
        bank: null,
        income: null
      }
    }
  },

  computed: {
    customerId() {
      return this.customer?.id
    }
  },

  created() {
    this.loadDocumentUrls()
  },

  methods: {
    async loadDocumentUrls() {
      if (!this.customerId) return

      this.loading = true
      try {
        const response = await this.$myfinbizApi.customer.getDocuments(this.customerId)
        const documentData = response.data?.data?.data
        if (documentData) {
          Object.entries(DOCUMENT_FILE_MAPPING).forEach(([type, apiField]) => {
            const fileData = documentData[apiField]
            this.documentUrls[type] = fileData?.url || null
          })
        }
      } catch (error) {
        console.error('Failed to load document URLs:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '서류 정보를 불러오는데 실패했습니다.',
          color: 'error'
        })
      } finally {
        this.loading = false
      }
    },

    handleUpload(type) {
      // TODO: 파일 업로드 처리
      console.log('Upload', type)
    },

    handleView(type) {
      const url = this.documentUrls[type]
      if (url) {
        window.open(url, '_blank')
      }
    },

    getFileName(url) {
      if (!url) return ''
      try {
        return decodeURIComponent(url.split('/').pop())
      } catch {
        return url.split('/').pop()
      }
    }
  }
}
</script>

<style scoped>
.v-dialog .v-card__text {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
