<template>
  <v-card outlined>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>플랫폼</v-list-item-title>
                <v-list-item-subtitle class="d-flex align-center">
                  {{ organizationName }}
                  <v-btn
                    x-small
                    color="primary"
                    class="ml-2"
                    @click="showPlatformDialog = true"
                  >
                    플랫폼 변경
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>이름</v-list-item-title>
                <v-list-item-subtitle>{{ userName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>사용자 ID</v-list-item-title>
                <v-list-item-subtitle>{{ userId }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>최종 수정일</v-list-item-title>
                <v-list-item-subtitle>{{ lastModified }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>정상고객여부</v-list-item-title>
                <v-list-item-subtitle>
                  <v-switch
                    v-model="isLoanable"
                    :loading="isUpdatingLoanable"
                    dense
                    hide-details
                    inset
                    @change="handleLoanableChange"
                  ></v-switch>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>상호명</v-list-item-title>
                <v-list-item-subtitle>{{ storeName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>주소</v-list-item-title>
                <v-list-item-subtitle>{{ storeAddress }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>연락처</v-list-item-title>
                <v-list-item-subtitle>{{ contactInfo }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- 플랫폼 변경 다이얼로그 -->
    <v-dialog v-model="showPlatformDialog" max-width="400">
      <v-card>
        <v-card-title>플랫폼 변경</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedOrganizationId"
            :items="organizations"
            item-text="name"
            item-value="id"
            label="플랫폼 선택"
            @change="onOrganizationSelect"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showPlatformDialog = false">취소</v-btn>
          <v-btn color="primary" @click="changeOrganization">변경</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 정상이용여부 변경 확인 다이얼로그 -->
    <v-dialog v-model="showLoanableDialog" max-width="400">
      <v-card>
        <v-card-title>정상이용여부 변경</v-card-title>
        <v-card-text>
          정상이용여부(선정산, 대출 모두)를 {{ pendingLoanableValue ? '가능' : '불가' }} 로 변경하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showLoanableDialog = false">취소</v-btn>
          <v-btn color="primary" @click="confirmLoanableChange">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
const MOBILE_CARRIERS = {
  skt: 'SKT',
  kt: 'KT',
  lgu: 'LG U+',
  mvno: '알뜰폰',
}

export default {
  name: 'UserSummary',
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showPlatformDialog: false,
      selectedOrganizationId: null,
      selectedOrganizationName: '',
      organizations: [],
      isUpdatingLoanable: false,
      showLoanableDialog: false,
      pendingLoanableValue: null
    }
  },

  async created() {
    try {
      const response = await this.$myfinbizApi.customer.getOrganizations()
      if (response.data?.data?.organizationsList) {
        this.organizations = response.data.data.organizationsList
      }
    } catch (error) {
      console.error('Failed to load organizations:', error)
      this.$store.dispatch('snackbar/showSnackbar', {
        text: '플랫폼 목록을 불러오는데 실패했습니다.',
        color: 'error',
      })
    }
  },

  computed: {
    organizationName() {
      return this.customer?.user?.customer?.organization?.name || '-'
    },
    userName() {
      return this.customer?.user?.name || '-'
    },
    userId() {
      return this.customer?.user?.id || '-'
    },
    lastModified() {
      const date = this.customer?.user?.customer?.modified
      return date ? this.formatDate(date) : '-'
    },
    storeName() {
      return this.customer?.user?.store?.name || '-'
    },
    storeAddress() {
      const address1 = this.customer?.user?.store?.address1 || ''
      const address2 = this.customer?.user?.store?.address2 || ''
      return address1 && address2
        ? `${address1} ${address2}`
        : address1 || address2 || '-'
    },
    contactInfo() {
      const phone = this.customer?.user?.phone || '-'
      const carrier = this.customer?.user?.mobileCarrier
      const carrierName = MOBILE_CARRIERS[carrier] || carrier || '-'
      return `${phone} (${carrierName})`
    },
    isLoanable: {
      get() {
        return this.customer?.user?.isLoanable === true
      },
      set(value) {
        if (this.customer?.user) {
          this.customer.user.isLoanable = value
        }
      }
    }
  },

  methods: {
    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return date.toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    },

    onOrganizationSelect(value) {
      const org = this.organizations.find((o) => o.id === value)
      this.selectedOrganizationName = org ? org.name : ''
    },

    async changeOrganization() {
      if (!this.selectedOrganizationId || !this.selectedOrganizationName) {
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '플랫폼을 선택해주세요.',
          color: 'error',
        })
        return
      }

      const confirmResult = await this.$dialog.confirm({
        text: `${this.storeName} 가맹점의 플랫폼을 ${this.selectedOrganizationName}으로 변경하시겠습니까?`,
        title: '플랫폼 변경 확인',
        persistent: true,
        actions: {
          false: '취소',
          true: '확인',
        },
      })

      if (!confirmResult) return

      try {
        await this.$myfinbizApi.customer.updateOrganization(
          this.selectedOrganizationId,
          this.customer?.id
        )

        // 플랫폼 변경 성공 후 매출계정 리셋 여부 확인
        const resetConfirmed = await this.$dialog.confirm({
          text: '플랫폼이 변경되었습니다. 이 가맹점의 기존 매출계정(여신협, 배민 등) 정보를 리셋하시겠습니까?',
          title: '매출계정 리셋 확인',
          persistent: true,
          actions: {
            false: '취소',
            true: '확인',
          },
        })

        if (resetConfirmed) {
          await this.$myfinbizApi.customer.deleteAllSalesCredentials(
            this.customer?.user?.id
          )
        }

        this.showPlatformDialog = false
        window.location.reload()
      } catch (error) {
        console.error('Error updating organization:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '플랫폼 변경 중 오류가 발생했습니다.',
          color: 'error',
        })
      }
    },

    handleLoanableChange(value) {
      this.pendingLoanableValue = value
      this.showLoanableDialog = true
    },

    async confirmLoanableChange() {
      this.isUpdatingLoanable = true
      try {
        await this.$myfinbizApi.customer.updateLoanableStatus(
          this.customer?.user?.id,
          this.pendingLoanableValue
        )

        // 성공 시 상태 업데이트
        if (this.customer?.user) {
          this.customer.user.isLoanable = this.pendingLoanableValue
        }

        this.$store.dispatch('snackbar/showSnackbar', {
          text: '정상이용여부가 변경되었습니다.',
          color: 'success'
        })
      } catch (error) {
        console.error('Error updating loanable status:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '정상이용여부 변경 중 오류가 발생했습니다.',
          color: 'error'
        })
      } finally {
        this.isUpdatingLoanable = false
        this.showLoanableDialog = false
        this.pendingLoanableValue = null
      }
    }
  },
}
</script>
