<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <span>가맹점 상세 정보</span>
            <v-btn color="primary" @click="$router.push({ name: 'myfinbiz/customers' })">
              목록으로
            </v-btn>
          </v-card-title>

          <!-- 탭 메뉴 -->
          <v-tabs v-model="activeTab" background-color="primary" dark>
            <v-tab>사용자 정보</v-tab>
            <v-tab>정산 정보</v-tab>
            <v-tab>거래 내역</v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab">
            <!-- 사용자 정보 탭 -->
            <v-tab-item>
              <UserInfo :customer-id="customerId" />
            </v-tab-item>

            <!-- 정산 정보 탭 -->
            <v-tab-item>
              <SettlementInfo :customer-id="customerId" />
            </v-tab-item>

            <!-- 거래 내역 탭 -->
            <v-tab-item>
              <TransactionHistory :customer-id="customerId" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import UserInfo from './components/UserInfo.vue'
import SettlementInfo from './components/SettlementInfo.vue'
import TransactionHistory from './components/TransactionHistory.vue'

export default {
  name: 'MyfinbizCustomerDetail',

  components: {
    UserInfo,
    SettlementInfo,
    TransactionHistory
  },

  data() {
    return {
      activeTab: 0,
    }
  },

  computed: {
    ...mapGetters('myfinbiz/auth', ['isAuthenticated']),
    customerId() {
      return parseInt(this.$route.params.id, 10)
    },
  },

  watch: {
    // URL의 tab 쿼리 파라미터가 변경될 때 탭 상태 업데이트
    '$route.query.tab': {
      immediate: true,
      handler(newTab) {
        this.activeTab = parseInt(newTab || 0)
      }
    },
    // 탭이 변경될 때 URL 업데이트
    activeTab(newTab) {
      if (this.$route.query.tab !== newTab.toString()) {
        this.$router.replace({
          query: { ...this.$route.query, tab: newTab }
        })
      }
    }
  },

  created() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'myfinbiz/login' })
    }
  },
}
</script>
