<template>
  <v-card outlined>
    <v-card-title class="d-flex justify-space-between align-center">
      계약 정보
      <v-btn v-if="isEdited" color="primary" small @click="saveChanges">
        저장
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td width="200">계약 상태</td>
              <td>
                <v-select
                  v-if="notAgency"
                  v-model="cloneContract.status"
                  :items="contractStatuses"
                  dense
                  hide-details
                  outlined
                  @change="handleStatusChange"
                />
                <v-chip v-else :color="contractStatusColor" small>
                  {{ contractStatusText }}
                </v-chip>
              </td>
            </tr>
            <tr>
              <td>메모</td>
              <td>{{ cloneContract.memoTm || '-' }}</td>
            </tr>
            <tr>
              <td>선정산 가능 여부</td>
              <td>
                <v-switch
                  v-if="notAgency"
                  v-model="cloneContract.isEarlyPaymentEnabled"
                  dense
                  hide-details
                  @change="handleEarlyPaymentChange"
                />
                <v-chip v-else :color="earlyPaymentColor" small>
                  {{ earlyPaymentText }}
                </v-chip>
              </td>
            </tr>
            <tr>
              <td>대출 서비스 가능 여부</td>
              <td>
                <v-switch
                  v-if="notAgency"
                  v-model="cloneContract.isLoanEnabled"
                  dense
                  hide-details
                  @change="handleLoanServiceChange"
                />
                <v-chip v-else :color="loanEnabledColor" small>
                  {{ loanEnabledText }}
                </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-divider class="my-4"></v-divider>

      <!-- 기존 계약서 및 동의서 테이블 유지 -->
      <div class="subtitle-1 mb-2">계약서 및 동의서</div>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>생성일</th>
              <th>종류</th>
              <th>만료일</th>
              <th>서명/이미지</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="agreement in formattedAgreements" :key="agreement.id">
              <td>{{ formatDate(agreement.created) }}</td>
              <td>{{ agreement.title }}</td>
              <td>
                {{ agreement.expireDate }}
                <template v-if="notAgency">
                  <v-btn
                    x-small
                    text
                    color="error"
                    @click="expireAgreement(agreement)"
                    class="ml-2"
                  >
                    만료시키기
                  </v-btn>
                </template>
              </td>
              <td>
                <div class="d-flex">
                  <v-btn
                    v-if="agreement.signatureFile"
                    x-small
                    text
                    color="primary"
                    :href="agreement.signatureFile"
                    target="_blank"
                    class="mr-2"
                  >
                    서명
                  </v-btn>
                  <v-btn
                    v-if="agreement.imageFile"
                    x-small
                    text
                    color="primary"
                    :href="agreement.imageFile"
                    target="_blank"
                  >
                    이미지
                  </v-btn>
                  <span v-if="!agreement.signatureFile && !agreement.imageFile"
                    >-</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

const CONTRACT_STATUS_COLORS = {
  started: 'success',
  pending: 'warning',
  terminated: 'error',
  suspended: 'grey',
}

export default {
  name: 'ContractInfo',
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      cloneContract: {},
      isEdited: false,
      contractStatuses: [],
      notAgency: true, // 실제로는 권한에 따라 설정되어야 함
      codes: null,
      initialized: false,
    }
  },

  async created() {
    await this.initCodes()
    this.initializeContract()
  },

  computed: {
    ...mapGetters('myfinbiz/auth', ['getCodes', 'getContractStatusCodes']),

    contractStatusColor() {
      return CONTRACT_STATUS_COLORS[this.cloneContract.status] || 'grey'
    },
    contractStatusText() {
      const codes = this.getCodes
      if (!codes?.fundanow?.ContractsContractStatus) {
        return this.cloneContract.status
      }

      const statusCode = this.cloneContract.status
      const statusInfo = codes.fundanow.ContractsContractStatus.find(
        (status) => status.code === statusCode
      )

      return statusInfo?.label || statusCode
    },
    earlyPaymentColor() {
      return this.cloneContract.isEarlyPaymentEnabled ? 'success' : 'error'
    },
    earlyPaymentText() {
      return this.cloneContract.isEarlyPaymentEnabled ? '가능' : '불가능'
    },
    loanEnabledColor() {
      return this.cloneContract.isLoanEnabled ? 'success' : 'error'
    },
    loanEnabledText() {
      return this.cloneContract.isLoanEnabled ? '가능' : '불가능'
    },
    formattedAgreements() {
      const agreements = this.customer?.user?.contract?.agreements?.filter((c) => c.imageFile && !c.offerId) || []

      console.log('formattedAgreements', agreements)

      const formatted = agreements.map((agreement) => ({
        id: agreement.id,
        created: agreement.created,
        title: agreement.term?.title || '-',
        agreedDate: agreement.agreedDate
          ? new Date(agreement.agreedDate).toLocaleDateString('ko-KR')
          : '-',
        expireDate: agreement.expireDate
          ? new Date(agreement.expireDate).toLocaleDateString('ko-KR')
          : '-',
        term: agreement.term,
        signatureFile: agreement.signatureFile?.url,
        imageFile: agreement.imageFile?.url,
      }))

      formatted.sort((a, b) => new Date(b.created) - new Date(a.created))

      return formatted
    },
  },

  methods: {
    async initCodes() {
      if (this.initialized) return

      this.initialized = true
      this.contractStatuses = this.getContractStatusCodes
    },

    formatDate(date) {
      if (!date) return '-'
      return new Date(date).toLocaleDateString('ko-KR')
    },

    initializeContract() {
      this.cloneContract = {
        id: this.customer?.user?.contract?.id,
        status: this.customer?.user?.contract?.status || 'pending',
        memoTm: this.customer?.user?.contract?.memoTm,
        isEarlyPaymentEnabled:
          this.customer?.user?.contract?.isEarlyPaymentEnabled || false,
        isLoanEnabled: this.customer?.user?.contract?.isLoanEnabled || false,
      }
    },

    handleStatusChange() {
      this.isEdited = true
    },

    handleEarlyPaymentChange() {
      this.isEdited = true
    },

    handleLoanServiceChange() {
      this.isEdited = true
    },

    async saveChanges() {
      try {
        await this.$myfinbizApi.customer.updateContract({
          id: this.cloneContract.id,
          status: this.cloneContract.status,
          isEarlyPaymentEnabled: this.cloneContract.isEarlyPaymentEnabled,
          isLoanEnabled: this.cloneContract.isLoanEnabled,
        })

        this.$store.dispatch('snackbar/showSnackbar', {
          text: '계약 정보가 성공적으로 수정되었습니다.',
          color: 'success',
        })

        this.isEdited = false
      } catch (error) {
        console.error('Failed to update contract:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '계약 정보 수정에 실패했습니다.',
          color: 'error',
        })
      }
    },

    async expireAgreement(item) {
      try {
        // const result = await this.$confirm('계약서를 만료 처리하시겠습니까?')
        // if (!result) return

        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        const expireDate = yesterday.toISOString().split('T')[0]

        console.log('expireAgreement', item)
        await this.$myfinbizApi.customer.updateAgreementExpireDate({
          id: Number(item.id),
          expireDate: expireDate,
        })

        // 로컬 데이터 업데이트
        const updatedAgreements = this.formattedAgreements.map((agreement) => {
          if (agreement.id === item.id) {
            return {
              ...agreement,
              expireDate: new Date(expireDate).toLocaleDateString('ko-KR'),
            }
          }
          return agreement
        })

        // 상태 업데이트
        this.customer.user.contract.agreements = updatedAgreements

        this.$store.dispatch('snackbar/showSnackbar', {
          text: '계약서가 만료 처리되었습니다.',
          color: 'success',
        })
      } catch (error) {
        console.error('계약서 만료 처리 실패:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '계약서 만료 처리에 실패했습니다.',
          color: 'error',
        })
      }
    },
  },
}
</script>

<style scoped>
.v-data-table ::v-deep td {
  height: 40px;
}
</style>
