<template>
  <v-card outlined>
    <v-card-title>거래 내역</v-card-title>
    <v-card-text>
      <!-- 필터 영역 -->
      <v-row class="mb-4">
        <v-col cols="12">
          <div class="d-flex align-center">
            <div class="mr-2">검색기준</div>
            <v-select
              v-model="filters.searchType"
              :items="searchTypeOptions"
              dense
              outlined
              hide-details
              class="mr-4"
              style="max-width: 200px"
            ></v-select>

            <v-menu
              v-model="dateMenu.start"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.startDate"
                  readonly
                  outlined
                  dense
                  hide-details
                  class="mr-2"
                  style="max-width: 150px"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.startDate"
                @change="dateMenu.start = false"
              ></v-date-picker>
            </v-menu>

            <span class="mx-2">~</span>

            <v-menu
              v-model="dateMenu.end"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.endDate"
                  readonly
                  outlined
                  dense
                  hide-details
                  class="mr-4"
                  style="max-width: 150px"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.endDate"
                @change="dateMenu.end = false"
              ></v-date-picker>
            </v-menu>

            <v-btn
              small
              color="primary"
              class="mr-2"
              @click="setDateRange('yesterday')"
              >어제</v-btn
            >
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click="setDateRange('1w')"
              >일주일</v-btn
            >
            <v-btn small color="primary" @click="setDateRange('1m')"
              >1개월</v-btn
            >
          </div>
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-center">
            <div class="mr-2">승인상태</div>
            <v-select
              v-model="filters.approvalStatus"
              :items="approvalStatusOptions"
              dense
              outlined
              hide-details
              class="mr-4"
              style="max-width: 200px"
            ></v-select>

            <div class="mr-2">매입상태</div>
            <v-select
              v-model="filters.purchaseStatus"
              :items="purchaseStatusOptions"
              dense
              outlined
              hide-details
              class="mr-4"
              style="max-width: 200px"
            ></v-select>

            <div class="mr-2">카드사</div>
            <v-select
              v-model="filters.cardCompany"
              :items="cardCompanyOptions"
              dense
              outlined
              hide-details
              class="mr-4"
              style="max-width: 200px"
            ></v-select>

            <div class="mr-2">거래일련번호</div>
            <v-text-field
              v-model="filters.transactionNumber"
              dense
              outlined
              hide-details
              class="mr-4"
              style="max-width: 200px"
            ></v-text-field>

            <v-btn color="primary" class="mr-2" @click="search">
              <v-icon left>mdi-magnify</v-icon>
              검색
            </v-btn>
            <v-btn @click="resetFilters">
              <v-icon left>mdi-refresh</v-icon>
              초기화
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- 요약 정보 -->
      <v-row class="mb-4">
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" width="33%">승인</th>
                  <th class="text-center" width="33%">취소</th>
                  <th class="text-center" width="33%">합계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    <div>건수: {{ summary.approval.count }}</div>
                    <div>금액: {{ formatAmount(summary.approval.amount) }}</div>
                  </td>
                  <td class="text-center">
                    <div>건수: {{ summary.cancel.count }}</div>
                    <div>금액: {{ formatAmount(summary.cancel.amount) }}</div>
                  </td>
                  <td class="text-center">
                    <div>건수: {{ summary.total.count }}</div>
                    <div>금액: {{ formatAmount(summary.total.amount) }}</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <!-- 거래 내역 테이블 -->
      <v-data-table
        :headers="headers"
        :items="transactions"
        :loading="loading"
        :server-items-length="totalTransactions"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
        dense
      >
        <!-- 금액 -->
        <template v-slot:item.amount="{ item }">
          {{ formatAmount(item.amount) }}
        </template>

        <!-- 결제회사 -->
        <template v-slot:item.paymentCompany="{ item }">
          {{ getCardCompanyLabel(item.paymentCompany) }}
        </template>

        <!-- 승인일자 -->
        <template v-slot:item.date="{ item }">
          {{ formatDate(item.date) }}
        </template>

        <!-- 매입일자 -->
        <template v-slot:item.purchasementDate="{ item }">
          {{ formatDate(item.purchasementDate) }}
        </template>

        <!-- 수수료 -->
        <template v-slot:item.paymentCompanyFee="{ item }">
          {{ formatAmount(item.paymentCompanyFee) }}
        </template>

        <!-- 지급예정금액 -->
        <template v-slot:item.depositScheduledAmount="{ item }">
          {{ formatAmount(item.depositScheduledAmount) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TransactionHistory',

  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },

  data() {
    const end = new Date()
    const start = new Date()
    end.setDate(end.getDate() - 1)
    start.setDate(start.getDate() - 1)

    return {
      loading: false,
      transactions: [],
      totalTransactions: 0,
      dateMenu: {
        start: false,
        end: false,
      },
      filters: {
        searchType: '승인일',
        startDate: start.toISOString().substr(0, 10),
        endDate: end.toISOString().substr(0, 10),
        approvalStatus: '전체',
        purchaseStatus: '전체',
        cardCompany: '전체',
        transactionNumber: '',
      },
      searchTypeOptions: ['승인일', '매입일'],
      approvalStatusOptions: ['전체', '승인', '취소'],
      purchaseStatusOptions: ['전체', '매입', '매입취소'],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['date'],
        sortDesc: [true],
      },
      headers: [
        { text: 'Id', value: 'id', width: '80' },
        { text: '결제유형', value: 'paymentType' },
        { text: '결제회사', value: 'paymentCompany' },
        { text: '가맹점번호', value: 'merchantCode' },
        { text: '거래번호', value: 'transactionId' },
        { text: '결제방법', value: 'paymentMethod' },
        { text: '승인상태', value: 'status' },
        { text: '승인일자', value: 'date' },
        { text: '승인금액', value: 'amount', align: 'right' },
        { text: '취소금액', value: 'cancelAmount', align: 'right' },
        { text: '취소일자', value: 'cancelDate' },
        { text: '매입상태', value: 'purchasementStatus' },
        { text: '매입일자', value: 'purchasementDate' },
        { text: '매입금액', value: 'purchasementAmount', align: 'right' },
        { text: '결제수수료', value: 'paymentCompanyFee', align: 'right' },
        {
          text: '지급예정금액',
          value: 'depositScheduledAmount',
          align: 'right',
        },
      ],
      summary: {
        approval: { count: 0, amount: 0 },
        cancel: { count: 0, amount: 0 },
        total: { count: 0, amount: 0 },
      },
    }
  },

  computed: {
    cardCompanyOptions() {
      const companies = this.$store.getters['myfinbiz/auth/getCodes']?.payment?.CardCompany || []
      return [
        { text: '전체', value: '전체' },
        ...companies.map(company => ({
          text: company.label,
          value: company.code,
        }))
      ]
    },
  },

  watch: {
    options: {
      handler() {
        this.loadTransactions()
      },
      deep: true,
    },
  },

  created() {
    this.loadTransactions()
  },

  methods: {
    async loadTransactions() {
      if (!this.customerId) return

      this.loading = true
      try {
        const response = await this.$myfinbizApi.customer.getTransactions(
          this.customerId,
          this.filters
        )

        // API 응답 구조 디버깅
        console.log('API Response:', response.data)

        // 응답 데이터 처리 - 데이터가 없는 경우 빈 배열로 처리
        const allTransactions = Array.isArray(response.data?.data.data)
          ? response.data.data.data
          : []

        this.totalTransactions = allTransactions.length

        // 정렬 처리
        const sortedTransactions = allTransactions.sort((a, b) => {
          const sortBy = this.options.sortBy[0]
          const sortDesc = this.options.sortDesc[0]
          if (!sortBy) return 0
          const aValue = a[sortBy]
          const bValue = b[sortBy]
          if (sortDesc) {
            return aValue > bValue ? -1 : 1
          } else {
            return aValue < bValue ? -1 : 1
          }
        })

        // 페이징 처리
        const { page, itemsPerPage } = this.options
        const start = (page - 1) * itemsPerPage
        const end = start + itemsPerPage
        this.transactions = sortedTransactions.slice(start, end)

        // 요약 정보 초기화
        const summary = {
          approval: { count: 0, amount: 0 },
          cancel: { count: 0, amount: 0 },
          total: { count: 0, amount: 0 },
        }

        // 요약 정보 계산
        allTransactions.forEach((transaction) => {
          if (transaction.status === '승인') {
            summary.approval.count++
            summary.approval.amount += Number(transaction.amount) || 0
          } else if (transaction.status === '취소') {
            summary.cancel.count++
            summary.cancel.amount += Number(transaction.cancelAmount) || 0
          }
          summary.total.count++
          summary.total.amount += Number(transaction.balance) || 0
        })

        this.summary = summary
      } catch (error) {
        console.error('Failed to load transactions:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '거래 내역을 불러오는데 실패했습니다.',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },

    formatAmount(amount) {
      if (!amount) return '0원'
      return new Intl.NumberFormat('ko-KR').format(amount) + '원'
    },

    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return date.toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    },

    search() {
      this.options.page = 1
      this.loadTransactions()
    },

    resetFilters() {
      const end = new Date()
      const start = new Date()
      end.setDate(end.getDate() - 1)
      start.setDate(start.getDate() - 1)

      this.filters = {
        searchType: '승인일',
        startDate: start.toISOString().substr(0, 10),
        endDate: end.toISOString().substr(0, 10),
        approvalStatus: '전체',
        purchaseStatus: '전체',
        cardCompany: '전체',
        transactionNumber: '',
      }
      this.search()
    },

    setDateRange(range) {
      const end = new Date()
      let start = new Date()

      switch (range) {
        case 'yesterday':
          start.setDate(end.getDate() - 1)
          end.setDate(end.getDate() - 1)
          break
        case '1w':
          start.setDate(end.getDate() - 7)
          break
        case '1m':
          start.setMonth(end.getMonth() - 1)
          break
      }

      this.filters.startDate = start.toISOString().substr(0, 10)
      this.filters.endDate = end.toISOString().substr(0, 10)
      this.search()
    },

    getCardCompanyLabel(code) {
      if (!code) return '-'
      const company = this.$store.getters['myfinbiz/auth/getCodes']?.payment?.CardCompany?.find(
        company => company.code === code
      )
      return company?.label || code
    },
  },
}
</script>

<style scoped>
.v-data-table ::v-deep td {
  height: 40px !important;
}
</style>
