<template>
  <v-card outlined>
    <v-card-title>신용조회 기록</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="creditEvaluations"
        :loading="loading"
        hide-default-footer
        dense
      >
        <!-- 신용등급 -->
        <template v-slot:item.grade="{ item }">
          <v-chip small :color="getGradeColor(item.grade)">
            {{ item.grade }}
          </v-chip>
        </template>

        <!-- 통과여부 -->
        <template v-slot:item.isQualified="{ item }">
          <v-chip small :color="item.isQualified ? 'success' : 'error'">
            {{ item.isQualified ? '통과' : '미통과' }}
          </v-chip>
        </template>

        <!-- 연체여부 -->
        <template v-slot:item.hasOverdueLoans="{ item }">
          <v-chip small :color="item.hasOverdueLoans ? 'error' : 'success'">
            {{ item.hasOverdueLoans ? '연체' : '정상' }}
          </v-chip>
        </template>

        <!-- 조회시간 -->
        <template v-slot:item.statusChanged="{ item }">
          {{ formatDate(item.statusChanged) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CreditInfo',
  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      creditEvaluations: [],
      headers: [
        { text: '등급', value: 'grade', width: '100' },
        { text: '통과여부', value: 'isQualified', width: '100' },
        { text: '연체여부', value: 'hasOverdueLoans', width: '100' },
        { text: '사유', value: 'reason' },
        { text: '조회시간', value: 'statusChanged', width: '150' }
      ]
    }
  },

  computed: {
    customerId() {
      return this.customer?.id
    }
  },

  created() {
    this.loadCreditEvaluations()
  },

  methods: {
    async loadCreditEvaluations() {
      if (!this.customerId) return

      this.loading = true
      try {
        const response = await this.$myfinbizApi.customer.getCreditEvaluations(this.customerId)
        const evaluationData = response.data?.data?.data
        if (evaluationData?.data) {
          this.creditEvaluations = evaluationData.data
        }
      } catch (error) {
        console.error('Failed to load credit evaluations:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '신용조회 정보를 불러오는데 실패했습니다.',
          color: 'error'
        })
      } finally {
        this.loading = false
      }
    },

    getGradeColor(grade) {
      const gradeNumber = parseInt(grade)
      if (gradeNumber <= 3) return 'success'
      if (gradeNumber <= 6) return 'warning'
      return 'error'
    },

    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return date.toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      })
    }
  }
}
</script>

<style scoped>
.v-data-table ::v-deep td {
  height: 40px !important;
}
</style>
