<template>
  <v-card outlined>
    <v-card-title>사용자 정보</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <div class="subtitle-1 mb-2">기본 정보</div>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>이메일:</v-list-item-title>
                <v-list-item-subtitle>{{
                  customer.user.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>전화번호:</v-list-item-title>
                <v-list-item-subtitle>{{
                  customer.user.phone
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>생년월일:</v-list-item-title>
                <v-list-item-subtitle>{{
                  customer.user.birthdate
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>주민번호:</v-list-item-title>
                <v-list-item-subtitle>{{
                  maskResidentNumber(customer.user.residentRegistrationNumber)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>가입일:</v-list-item-title>
                <v-list-item-subtitle>{{
                  formatDate(customer.user.created)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6">
          <div class="subtitle-1 mb-2">사업장 정보</div>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>상점명:</v-list-item-title>
                <v-list-item-subtitle>{{
                  customer.user.store.name
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>사업자번호:</v-list-item-title>
                <v-list-item-subtitle>{{
                  customer.user.store.businessLicenseNumber
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>사업장주소:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ customer.user.store.address1 }}
                  <template v-if="customer.user.store.address2">
                    {{ customer.user.store.address2 }}
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>업종:</v-list-item-title>
                <v-list-item-subtitle>{{
                  customer.user.store.typeOfBusiness
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>폐업여부:</v-list-item-title>
                <v-chip
                  small
                  :color="customer.user.store.isClosed ? 'error' : 'success'"
                >
                  {{ customer.user.store.isClosed ? '폐업' : '운영중' }}
                </v-chip>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <div class="subtitle-1 mb-2">매출 정보 수집</div>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>제공사</th>
              <th>아이디</th>
              <th>비밀번호</th>
              <th>상태</th>
              <th>상태 변경일</th>
              <th>스크래핑 여부</th>
              <th>작업</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="credential in customer.user.salesCredentials"
              :key="credential.id"
            >
              <td>{{ credential.provider }}</td>
              <td>{{ credential.userId || '-' }}</td>
              <td class="password-cell">
                <span class="password-text">{{ passwordVisibility[credential.id] ? (credential.userPassword || '-') : maskPassword(credential.userPassword) }}</span>
                <v-btn
                  x-small
                  text
                  color="primary"
                  class="visibility-btn"
                  @click="togglePasswordVisibility(credential.id)"
                >
                  <v-icon x-small>
                    {{ passwordVisibility[credential.id] ? 'mdi-eye-off' : 'mdi-eye' }}
                  </v-icon>
                </v-btn>
              </td>
              <td>
                <v-chip x-small :color="getStatusColor(credential.status)">
                  {{ getStatusText(credential.status) }}
                </v-chip>
              </td>
              <td>{{ formatDate(credential.statusChanged) }}</td>
              <td>
                <v-switch
                  v-model="credential.isForSalesScraping"
                  dense
                  hide-details
                  inset
                  :loading="credential.isUpdating"
                  @change="handleScrapingChange(credential)"
                  class="mt-0 pt-0"
                ></v-switch>
              </td>
              <td>
                <v-btn
                  x-small
                  text
                  color="primary"
                  @click="openEditDialog(credential)"
                >
                  수정
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!-- 계정 수정 다이얼로그 -->
      <v-dialog v-model="showEditDialog" max-width="500">
        <v-card>
          <v-card-title>계정 정보 수정</v-card-title>
          <v-card-text>
            <v-form ref="editForm" v-model="isEditFormValid">
              <v-text-field
                v-model="editForm.userId"
                label="아이디"
                :rules="[v => !!v || '아이디를 입력해주세요']"
                required
              ></v-text-field>
              <v-text-field
                v-model="editForm.userPassword"
                label="비밀번호"
                :rules="[v => !!v || '비밀번호를 입력해주세요']"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showEditDialog = false">취소</v-btn>
            <v-btn
              color="primary"
              :disabled="!isEditFormValid"
              :loading="isUpdating"
              @click="handleEditSubmit"
            >
              저장
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 도큐먼트 조회 다이얼로그 -->
      <v-dialog v-model="showDocumentDialog" max-width="800">
        <v-card>
          <v-card-title>
            도큐먼트 조회
            <v-spacer></v-spacer>
            <v-btn icon @click="showDocumentDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-img
              :src="selectedDocumentUrl"
              max-height="600"
              contain
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      timeout="3000"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: 'UserDetail',
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDocumentDialog: false,
      selectedDocumentUrl: null,
      showEditDialog: false,
      isEditFormValid: true,
      editForm: {
        userId: '',
        userPassword: '',
      },
      isUpdating: false,
      selectedCredential: null,
      snackbar: {
        show: false,
        text: '',
        color: 'success',
      },
      passwordVisibility: {},
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '-'
      return new Date(dateString).toLocaleString()
    },
    getStatusColor(status) {
      const colors = {
        completed: 'success',
        pending: 'warning',
        failed: 'error',
      }
      return colors[status] || 'grey'
    },
    getStatusText(status) {
      const texts = {
        completed: '완료',
        pending: '대기',
        failed: '실패',
      }
      return texts[status] || status
    },
    maskResidentNumber(number) {
      if (!number) return '-'
      return number.replace(/(\d{6})\d{7}/, '$1***********')
    },
    showSnackbar(text, color = 'success') {
      this.snackbar.show = true
      this.snackbar.text = text
      this.snackbar.color = color
    },
    async handleScrapingChange(credential) {
      this.$set(credential, 'isUpdating', true)
      try {
        await this.$myfinbizApi.customer.updateIsForSalesScraping({
          id: credential.id,
          isForSalesScraping: credential.isForSalesScraping,
        })

        this.showSnackbar('선정산 소스가 변경되었습니다.')
      } catch (error) {
        console.error('Error updating sales credentials:', error)
        credential.isForSalesScraping = !credential.isForSalesScraping
        this.showSnackbar('선정산 소스 변경에 실패했습니다.', 'error')
      } finally {
        this.$set(credential, 'isUpdating', false)
      }
    },
    showDocument(url) {
      this.selectedDocumentUrl = url
      this.showDocumentDialog = true
    },
    openEditDialog(credential) {
      this.selectedCredential = credential
      this.editForm.userId = credential.userId || ''
      this.editForm.userPassword = credential.userPassword || ''
      this.showEditDialog = true
    },
    async handleEditSubmit() {
      if (!this.isEditFormValid) return

      this.isUpdating = true
      try {
        await this.$myfinbizApi.customer.updateSalesCredentials({
          id: this.selectedCredential.id,
          userId: this.editForm.userId,
          userPassword: this.editForm.userPassword,
        })

        // 성공 시 로컬 데이터도 업데이트
        this.selectedCredential.userId = this.editForm.userId
        this.selectedCredential.userPassword = this.editForm.userPassword

        this.showSnackbar('선정산 계정 정보가 성공적으로 업데이트되었습니다.')
        this.showEditDialog = false
      } catch (error) {
        console.error('Error updating sales credential:', error)
        this.showSnackbar('선정산 계정 정보 업데이트에 실패했습니다.', 'error')
      } finally {
        this.isUpdating = false
      }
    },
    togglePasswordVisibility(credentialId) {
      this.$set(this.passwordVisibility, credentialId, !this.passwordVisibility[credentialId])
    },
    maskPassword(password) {
      if (!password) return '-'
      return '*'.repeat(password.length)
    },
  },
}
</script>

<style scoped>
.v-switch {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.password-cell {
  position: relative;
  height: 100%;
  padding: 12px 16px !important;
}

.password-text {
  vertical-align: middle;
}

.visibility-btn {
  position: absolute !important;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 !important;
  min-width: 24px !important;
  width: 24px;
  height: 24px;
}
</style>
