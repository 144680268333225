<template>
  <v-card class="mb-4">
    <v-card-title>사용자 가격 정책</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="[
          { text: '항목', value: 'label', width: '150px' },
          { text: '내용', value: 'content' },
        ]"
        :items="tableItems"
        hide-default-footer
        :loading="loading"
        class="elevation-0"
      >
        <template v-slot:item.content="{ item }">
          <div v-if="item.type === 'status-message'" class="pl-4">
            계약서의 상태가 "개시" 여서 변경이 불가능 합니다.
          </div>
          <div v-else-if="item.type === 'fee-input'" class="d-flex">
            <v-select
              v-model="feeType.feeType"
              :items="feeTypes"
              item-text="label"
              item-value="value"
              dense
              outlined
              hide-details
              class="mr-2"
              style="width: 150px"
              :disabled="formDisabled"
            ></v-select>
            <v-text-field
              v-model="feeType.feeValue"
              dense
              outlined
              hide-details
              placeholder="단위를 입력하세요"
              :suffix="feeType.feeType === 'daily_rate' ? '%' : '원'"
              :disabled="formDisabled"
              style="width: 150px"
            ></v-text-field>
          </div>
          <div v-else-if="item.type === 'interest-input'">
            <v-text-field
              v-model="feeType.interestRate"
              dense
              outlined
              hide-details
              placeholder="이자율"
              suffix="%"
              :disabled="formDisabled"
              style="width: 150px"
            ></v-text-field>
          </div>
          <div v-else-if="item.type === 'transfer-fee-input'">
            <v-text-field
              v-model="feeType.transferFeeValue"
              dense
              outlined
              hide-details
              placeholder="이체수수료"
              suffix="원"
              :disabled="formDisabled"
              style="width: 150px"
            ></v-text-field>
          </div>
          <div v-else-if="item.type === 'applied-date'">
            {{ formatDate(feeType.appliedDate) }}
          </div>
          <div v-else-if="item.type === 'buttons'" class="d-flex">
            <v-btn
              color="primary"
              small
              class="mr-2"
              :disabled="formDisabled"
              @click="saveFee()"
            >
              저장
            </v-btn>
            <v-btn
              v-if="feeType.id"
              color="error"
              small
              :disabled="formDisabled"
              @click="saveFee('clear')"
            >
              삭제
            </v-btn>
          </div>
          <div v-else-if="item.type === 'add-button'">
            <v-btn color="primary" small block @click="editabled = true">
              추가
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PricingPolicy',

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      editabled: false,
      feeTypes: [
        { label: '월정액', value: 'monthly_amount' },
        { label: '일정액', value: 'daily_amount' },
        { label: '일정률', value: 'daily_rate' },
      ],
      feeType: this.initFeeType(),
    }
  },

  computed: {
    customerId() {
      return (
        this.customer &&
        this.customer.user &&
        this.customer.user.customer &&
        this.customer.user.customer.id
      )
    },

    formDisabled() {
      return (
        this.customer &&
        this.customer.user &&
        this.customer.user.contract &&
        this.customer.user.contract.status === 'started'
      )
    },

    isPercent() {
      return this.feeType.feeType && this.feeType.feeType.includes('_rate')
    },

    inputFeeTypeData() {
      return {
        customerId: this.customerId,
        feeType: this.feeType.feeType,
        feeValue: this.isPercent
          ? Number(this.feeType.feeValue) / 100
          : this.feeType.feeValue,
        interestRate: Number(this.feeType.interestRate) / 100,
        transferFeeValue: this.feeType.transferFeeValue,
      }
    },

    tableItems() {
      const items = []

      if (
        this.customer &&
        this.customer.user &&
        this.customer.user.contract &&
        this.customer.user.contract.status === 'started'
      ) {
        items.push({ label: '', content: '', type: 'status-message' })
      }

      if (this.editabled) {
        items.push(
          { label: '이용료 수취 대행', content: '', type: 'fee-input' },
          { label: '선정산 이자율', content: '', type: 'interest-input' },
          { label: '이체수수료', content: '', type: 'transfer-fee-input' }
        )

        if (this.feeType.appliedDate) {
          items.push({ label: '적용일', content: '', type: 'applied-date' })
        }

        items.push({ label: '', content: '', type: 'buttons' })
      } else {
        items.push({ label: '', content: '', type: 'add-button' })
      }

      return items
    },
  },

  watch: {
    customerId: {
      immediate: true,
      handler: 'loadData',
    },
    'feeType.feeType'(newValue, oldValue) {
      this.checkEditable()

      // 수수료 유형이 변경될 때 기본값 설정
      if (newValue && newValue !== oldValue) {
        switch (newValue) {
          case 'monthly_amount':
            this.feeType.feeValue = 60000
            break
          case 'daily_amount':
            this.feeType.feeValue = 2200
            break
          case 'daily_rate':
            this.feeType.feeValue = 0.4
            break
        }
      }
    },
    'feeType.feeValue'() {
      this.checkEditable()
    },
    'feeType.interestRate'() {
      this.checkEditable()
    },
    'feeType.transferFeeValue'() {
      this.checkEditable()
    },
  },

  methods: {
    initFeeType() {
      return {
        customerId: this.customerId,
        feeType: null,
        feeValue: null,
        interestRate: null,
        appliedDate: null,
        transferFeeValue: null,
      }
    },

    checkEditable() {
      if (
        this.feeType.feeType ||
        this.feeType.feeValue ||
        this.feeType.interestRate ||
        this.feeType.transferFeeValue
      ) {
        this.editabled = true
      } else {
        this.editabled = false
      }
    },

    formatDate(date) {
      if (!date) return ''
      const d = new Date(date)
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(d.getDate()).padStart(2, '0')}`
    },

    async loadData() {
      if (!this.customerId) return

      try {
        this.loading = true
        const response = await this.$myfinbizApi.customer.getCustomerFeeSetting(
          this.customerId
        )
        const data = response.data.data.data

        if (
          data &&
          (data.feeValue || data.interestRate || data.transferFeeValue)
        ) {
          this.feeType = {
            id: data.id,
            feeType: data.feeType,
            feeValue:
              data.feeType && data.feeType.includes('_rate')
                ? data.feeValue * 100
                : data.feeValue,
            interestRate: data.interestRate
              ? data.interestRate * 100
              : data.interestRate,
            transferFeeValue: data.transferFeeValue,
            appliedDate: data.appliedDate,
          }
        }
      } catch (error) {
        console.error('Failed to load fee settings:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '가격 정책 정보를 불러오는데 실패했습니다.',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },

    async saveFee(mode) {
      try {
        this.loading = true

        if (mode === 'clear') {
          const isConfirmed = window.confirm('삭제 하시겠습니까?')
          if (!isConfirmed) {
            this.loading = false
            return
          }

          this.feeType = this.initFeeType()
        } else {
          const isConfirmed = window.confirm('저장 하시겠습니까?')
          if (!isConfirmed) {
            this.loading = false
            return
          }
        }

        await this.$myfinbizApi.customer.createCustomerFeeSetting(
          this.inputFeeTypeData
        )
        await this.loadData()

        this.$store.dispatch('snackbar/showSnackbar', {
          text: mode === 'clear' ? '삭제되었습니다.' : '저장되었습니다.',
          color: 'success',
        })
      } catch (error) {
        console.error('Failed to save fee settings:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '가격 정책 저장에 실패했습니다.',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
