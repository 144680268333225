<template>
  <v-container fluid>
    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title>비밀번호 초기화</v-card-title>
        <v-card-text>{{ confirmMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDialog = false"
            >취소</v-btn
          >
          <v-btn color="primary" @click="handleConfirm">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alertDialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-alert :type="alertType" class="mb-0">
            {{ alertMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="alertDialog = false">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updatePasswordDialog" max-width="500px">
      <v-card>
        <v-card-title>비밀번호 직접 변경</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newPassword"
            label="새 비밀번호"
            :error-messages="passwordError"
            @input="passwordError = ''"
          ></v-text-field>
          <div>고객 비밀번호를 위와 같이 변경하시겠습니까?</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="updatePasswordDialog = false"
            >취소</v-btn
          >
          <v-btn color="primary" @click="handleUpdatePassword">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="contractSmsDialog" max-width="600px">
      <v-card>
        <v-card-title>계약서 작성 안내문자 발송</v-card-title>
        <v-card-text>
          <div
            class="text-body-2 mb-4"
            style="
              white-space: pre-wrap;
              word-break: break-word;
              background-color: #f0f0f0;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-family: monospace;
              text-align: left;
            "
          >
            {{ contractSmsMessage }}
          </div>
          <div class="font-weight-bold">
            위 내용으로 문자를 발송하시겠습니까?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="contractSmsDialog = false"
            >취소</v-btn
          >
          <v-btn color="primary" @click="handleSendContractSms">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="emergencyLoanSmsDialog" max-width="600px">
      <v-card>
        <v-card-title>비상금론 작성 안내문자 발송</v-card-title>
        <v-card-text>
          <div
            class="text-body-2 mb-4"
            style="
              white-space: pre-wrap;
              word-break: break-word;
              background-color: #f0f0f0;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-family: monospace;
              text-align: left;
            "
          >
            {{ emergencyLoanSmsMessage }}
          </div>
          <div class="font-weight-bold">
            위 내용으로 문자를 발송하시겠습니까?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emergencyLoanSmsDialog = false"
            >취소</v-btn
          >
          <v-btn color="primary" @click="handleSendEmergencyLoanSms"
            >확인</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="customerViewDialog" max-width="500px">
      <v-card>
        <v-card-title>고객 화면 보기</v-card-title>
        <v-card-text>
          <div class="font-weight-bold">고객 화면 보기를 하시겠습니까?</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="customerViewDialog = false"
            >취소</v-btn
          >
          <v-btn color="primary" @click="handleCustomerView">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col>
        <div v-if="loading">
          <v-alert type="info"> 가맹점 정보를 불러오는 중... </v-alert>
        </div>
        <div v-else-if="error">
          <v-alert type="error">
            {{ error }}
          </v-alert>
        </div>
        <div v-else-if="customer">
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="primary"
                class="mr-2"
                :loading="resetPasswordLoading"
                @click="resetPassword"
              >
                비밀번호 초기화 문자
              </v-btn>
              <v-btn
                color="primary"
                class="mr-2"
                :loading="resetPasswordLoading"
                @click="showUpdatePasswordDialog"
              >
                임시 비밀번호 설정
              </v-btn>
              <v-btn
                color="primary"
                class="mr-2"
                :loading="resetPasswordLoading"
                @click="showContractSmsDialog"
              >
                계약서 작성 안내문자
              </v-btn>
              <v-btn
                color="primary"
                class="mr-2"
                :loading="resetPasswordLoading"
                @click="showEmergencyLoanSmsDialog"
              >
                비상금론 작성 안내문자
              </v-btn>
              <v-btn
                color="primary"
                :loading="resetPasswordLoading"
                @click="showCustomerViewDialog"
              >
                고객 화면 보기
              </v-btn>
            </v-col>
            <v-col cols="12">
              <UserSummary :customer="customer" />
            </v-col>
            <v-col cols="12">
              <UserDetail :customer="customer" />
            </v-col>
            <v-col cols="12">
              <ContractInfo :customer="customer" />
            </v-col>
            <v-col cols="12">
              <DocumentInfo :customer="customer" />
            </v-col>
            <v-col cols="12">
              <CreditInfo :customer="customer" />
            </v-col>
            <v-col cols="12">
              <RefundInfo :customer="customer" />
            </v-col>
            <v-col cols="12">
              <AccountInfo :customer="customer" />
            </v-col>
            <v-col cols="12">
              <PricingPolicy :customer="customer" />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserSummary from './UserSummary.vue'
import UserDetail from './UserDetail.vue'
import ContractInfo from './ContractInfo.vue'
import AccountInfo from './AccountInfo.vue'
import PricingPolicy from './PricingPolicy.vue'
import DocumentInfo from './DocumentInfo.vue'
import CreditInfo from './CreditInfo.vue'
import RefundInfo from './RefundInfo.vue'

export default {
  name: 'UserInfo',
  components: {
    UserSummary,
    UserDetail,
    ContractInfo,
    AccountInfo,
    PricingPolicy,
    DocumentInfo,
    CreditInfo,
    RefundInfo,
  },

  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      customer: null,
      resetPasswordLoading: false,
      confirmDialog: false,
      confirmMessage: '',
      alertDialog: false,
      alertMessage: '',
      alertType: 'success',
      resetToken: null,
      updatePasswordDialog: false,
      newPassword: 'funda1234',
      passwordError: '',
      contractSmsDialog: false,
      contractSmsMessage: '',
      emergencyLoanSmsDialog: false,
      emergencyLoanSmsMessage: '',
      customerViewDialog: false,
    }
  },

  created() {
    this.loadCustomerInfo()
  },

  methods: {
    async loadCustomerInfo() {
      this.loading = true
      try {
        const response = await this.$myfinbizApi.customer.getDetail(
          this.customerId
        )
        console.log('Full API Response:', response)
        console.log('Response data:', response.data)
        console.log('Customer data:', response.data.data)
        this.customer = response.data.data.data
        console.log('Final customer object:', this.customer)
        console.log('User object:', this.customer?.user)
        console.log('Account info:', {
          repayment: this.customer?.user?.repaymentAccount,
          collection: this.customer?.user?.collectionAccount,
          withdrawal: this.customer?.user?.withdrawalAccount,
        })
      } catch (error) {
        console.error('Failed to load customer info:', error)
        this.error = '가맹점 정보를 불러오는데 실패했습니다.'
      } finally {
        this.loading = false
      }
    },

    async resetPassword() {
      if (this.resetPasswordLoading) return
      try {
        this.resetPasswordLoading = true
        const response =
          await this.$myfinbizApi.customer.createPasswordResetToken(
            this.customer.user.id
          )
        this.resetToken = response.data.data.data.token
        this.confirmMessage = `고객에게 비밀번호 초기화 문자를 발송하시겠습니까?\nhttps://${this.customer?.user?.customer?.organization?.host}/auth/password-reset?resetToken=${this.resetToken}`
        this.confirmDialog = true
      } catch (error) {
        console.error('Failed to reset password:', error)
        this.alertType = 'error'
        this.alertMessage = '비밀번호 초기화 처리 중 오류가 발생했습니다.'
        this.alertDialog = true
      } finally {
        this.resetPasswordLoading = false
      }
    },

    async handleConfirm() {
      try {
        this.resetPasswordLoading = true
        await this.$myfinbizApi.customer.sendPasswordResetUrl({
          userId: this.customer.user.id,
          url: `https://${this.customer?.user?.customer?.organization?.host}/auth/password-reset?resetToken=${this.resetToken}`,
        })
        this.alertType = 'success'
        this.alertMessage = '비밀번호 초기화 메세지 발송에 성공하였습니다.'
        this.alertDialog = true
      } catch (error) {
        console.error('Failed to send reset URL:', error)
        this.alertType = 'error'
        this.alertMessage = '비밀번호 초기화 처리 중 오류가 발생했습니다.'
        this.alertDialog = true
      } finally {
        this.resetPasswordLoading = false
        this.confirmDialog = false
      }
    },

    showUpdatePasswordDialog() {
      this.newPassword = 'funda1234'
      this.passwordError = ''
      this.updatePasswordDialog = true
    },

    validatePassword(password) {
      if (!password) {
        return '비밀번호를 입력해주세요'
      }
      return null
    },

    async handleUpdatePassword() {
      const error = this.validatePassword(this.newPassword)
      if (error) {
        this.passwordError = error
        return
      }

      try {
        this.resetPasswordLoading = true
        const response =
          await this.$myfinbizApi.customer.createPasswordResetToken(
            this.customer.user.id
          )
        const token = response.data.data.data.token

        await this.$myfinbizApi.customer.resetPassword(token, this.newPassword)
        this.updatePasswordDialog = false
        this.alertType = 'success'
        this.alertMessage = '비밀번호가 성공적으로 변경되었습니다.'
        this.alertDialog = true
      } catch (error) {
        console.error('Failed to update password:', error)
        this.alertType = 'error'
        this.alertMessage =
          error.message || '비밀번호 변경 중 오류가 발생했습니다.'
        this.alertDialog = true
      } finally {
        this.resetPasswordLoading = false
      }
    },

    showContractSmsDialog() {
      const messageContent = `${this.customer.user.name} 고객님 반갑습니다. ${this.customer.user.customer.organization.name} 계약서 작성을 위해서 로그인을 해주세요. 
- 링크 : https://${this.customer.user.customer.organization.host}
- 아이디 : ${this.customer.user.email}

문의 사항이 있으시면 채널톡에 남겨주세요!
채널톡 링크 : https://xkej0.channel.io/`

      this.contractSmsMessage = messageContent
      this.contractSmsDialog = true
    },

    async handleSendContractSms() {
      try {
        this.resetPasswordLoading = true
        await this.$myfinbizApi.customer.sendContractSms({
          userId: this.customer.user.id,
          title: '계약서 작성 안내문자',
          message: this.contractSmsMessage,
        })
        this.contractSmsDialog = false
        this.alertType = 'success'
        this.alertMessage = '계약서 작성 안내문자 발송에 성공하였습니다.'
        this.alertDialog = true
      } catch (error) {
        console.error('Failed to send contract SMS:', error)
        this.alertType = 'error'
        this.alertMessage = '계약서 작성 안내문자 발송 중 오류가 발생했습니다.'
        this.alertDialog = true
      } finally {
        this.resetPasswordLoading = false
      }
    },

    showEmergencyLoanSmsDialog() {
      const messageContent = `안녕하세요. 펀다입니다.
신청하신 비상금론 한도 산출 방법입니다.

1. 아래 링크로 ${this.customer.user.customer.organization.name} 로그인(임시 비밀번호 이용) 
- 링크 : https://${this.customer.user.customer.organization.host}
- 아이디 : ${this.customer.user.email}
- 임시 비밀번호 : funda1234

2. 화면에 나오는 순서대로 정보 입력 후

3. 500만원 비상금론 신청하기를 누르고 개인 인증을 하시면 한도 확인이 됩니다.

신청 중 궁금하신 것은 아래 채널톡으로 문의 주시면 안내드리겠습니다.
(신청자가 많아 전화통화가 어렵습습니다)

채널톡 링크: https://xkej0.channel.io/`

      this.emergencyLoanSmsMessage = messageContent
      this.emergencyLoanSmsDialog = true
    },

    async handleSendEmergencyLoanSms() {
      try {
        this.resetPasswordLoading = true
        await this.$myfinbizApi.customer.sendContractSms({
          userId: this.customer.user.id,
          title: '비상금론 작성 안내문자',
          message: this.emergencyLoanSmsMessage,
        })
        this.emergencyLoanSmsDialog = false
        this.alertType = 'success'
        this.alertMessage = '비상금론 작성 안내문자 발송에 성공하였습니다.'
        this.alertDialog = true
      } catch (error) {
        console.error('Failed to send emergency loan SMS:', error)
        this.alertType = 'error'
        this.alertMessage =
          '비상금론 작성 안내문자 발송 중 오류가 발생했습니다.'
        this.alertDialog = true
      } finally {
        this.resetPasswordLoading = false
      }
    },

    showCustomerViewDialog() {
      this.customerViewDialog = true
    },

    async handleCustomerView() {
      try {
        this.resetPasswordLoading = true
        const response = await this.$myfinbizApi.customer.getLoginToken(
          this.customer.user.id
        )
        const token = response.data.data.data
        console.log(`?secret_token=${token}`)

        window.open(
          `https://${this.customer.user.customer.organization.host}/?secret_token=${token}`,
          '_blank'
        )
        this.customerViewDialog = false
      } catch (error) {
        console.error('Failed to get login token:', error)
        this.alertType = 'error'
        this.alertMessage = '고객 화면 보기 처리 중 오류가 발생했습니다.'
        this.alertDialog = true
      } finally {
        this.resetPasswordLoading = false
      }
    },
  },
}
</script>
