<template>
  <v-card outlined>
    <v-card-title>
      계좌 정보
    </v-card-title>
    <v-card-text>
      <v-row>
        <!-- 매출 집금 계좌 -->
        <v-col cols="12" md="4">
          <v-card outlined>
            <v-card-title class="subtitle-1">매출 집금 계좌</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>은행</v-list-item-title>
                    <v-list-item-subtitle>{{ collectionAccount && collectionAccount.bank || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>계좌번호</v-list-item-title>
                    <v-list-item-subtitle>{{ collectionAccount && collectionAccount.accountNumber || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>예금주</v-list-item-title>
                    <v-list-item-subtitle>{{ collectionAccount && collectionAccount.holderName || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>최종 수정일</v-list-item-title>
                    <v-list-item-subtitle>{{ collectionAccount && collectionAccount.modified || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- 입금용 주계좌 -->
        <v-col cols="12" md="4">
          <v-card outlined>
            <v-card-title class="subtitle-1">입금용 주계좌</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>은행</v-list-item-title>
                    <v-list-item-subtitle>{{ withdrawalAccount && withdrawalAccount.bank || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>계좌번호</v-list-item-title>
                    <v-list-item-subtitle>{{ withdrawalAccount && withdrawalAccount.accountNumber || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>예금주</v-list-item-title>
                    <v-list-item-subtitle>{{ withdrawalAccount && withdrawalAccount.holderName || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>최종 수정일</v-list-item-title>
                    <v-list-item-subtitle>{{ withdrawalAccount && withdrawalAccount.modified || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- 상환용 가상계좌 -->
        <v-col cols="12" md="4">
          <v-card outlined>
            <v-card-title class="subtitle-1">상환용 가상계좌</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>은행</v-list-item-title>
                    <v-list-item-subtitle>{{ repaymentAccount && repaymentAccount.bank || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>계좌번호</v-list-item-title>
                    <v-list-item-subtitle>{{ repaymentAccount && repaymentAccount.accountNumber || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>예금주</v-list-item-title>
                    <v-list-item-subtitle>{{ repaymentAccount && repaymentAccount.holderName || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>최종 수정일</v-list-item-title>
                    <v-list-item-subtitle>{{ repaymentAccount && repaymentAccount.modified || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AccountInfo',
  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  computed: {
    // 상환 계좌 정보
    repaymentAccount() {
      console.log('Customer prop:', this.customer)
      console.log('User data:', this.customer?.user)
      console.log('Repayment account:', this.customer?.user?.repaymentAccount)

      const account = this.customer?.user?.repaymentAccount
      if (!account) return null

      const bankCode = this.$store.getters['myfinbiz/auth/getCodes']?.payment?.AllowBanks?.find(
        bank => bank.code === account.bank
      )

      return {
        bank: bankCode?.label || account.bank || '-',
        accountNumber: account.accountNumber || '-',
        holderName: account.holderName || '-',
        modified: account.modified ? new Date(account.modified).toLocaleString() : '-'
      }
    },

    // 매출 집금 계좌 정보
    collectionAccount() {
      console.log('Collection account:', this.customer?.user?.collectionAccount)

      const account = this.customer?.user?.collectionAccount
      if (!account) return null

      const bankCode = this.$store.getters['myfinbiz/auth/getCodes']?.payment?.AllowBanks?.find(
        bank => bank.code === account.bank
      )

      return {
        bank: bankCode?.label || account.bank || '-',
        accountNumber: account.accountNumber || '-',
        holderName: account.holderName || '-',
        modified: account.modified ? new Date(account.modified).toLocaleString() : '-'
      }
    },

    // 출금 계좌 정보
    withdrawalAccount() {
      console.log('Withdrawal account:', this.customer?.user?.withdrawalAccount)

      const account = this.customer?.user?.withdrawalAccount
      if (!account) return null

      const bankCode = this.$store.getters['myfinbiz/auth/getCodes']?.payment?.AllowBanks?.find(
        bank => bank.code === account.bank
      )

      return {
        bank: bankCode?.label || account.bank || '-',
        accountNumber: account.accountNumber || '-',
        holderName: account.holderName || '-',
        modified: account.modified ? new Date(account.modified).toLocaleString() : '-'
      }
    }
  }
}
</script>
